import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container, Row, Col, Visible } from 'react-grid-system';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import Link from 'components/Link';
import Img from 'components/Img';
import { capitalCase } from 'change-case';

const Wrapper = styled.article`
  & + & {
    margin-top: 100px;
  }
`;

const StyledImg = styled(Img)`
  max-width: 100%;
`;

function useColsProps(contentPosition) {
  return useMemo(() => {
    return {
      contentColProps: {
        push: contentPosition === 'right' ? { md: 6 } : {},
      },
      imageColProps: {
        pull: contentPosition === 'right' ? { md: 6 } : {},
        push: contentPosition === 'right' ? {} : { md: 1 },
      },
    };
  }, [contentPosition]);
}

function BlogListItem({
  className,
  category,
  title,
  content,
  slug,
  featuredImage,
  contentPosition,
}) {
  const { contentColProps, imageColProps } = useColsProps(contentPosition);
  return (
    <Wrapper className={className}>
      <Container>
        <Row align="normal">
          <Col md={6} {...contentColProps}>
            <Heading title={title} name={capitalCase(category)} />
            {featuredImage && (
              <Visible xs sm>
                <Img {...featuredImage} />
              </Visible>
            )}
            <Paragraph>{content}</Paragraph>
            <Link to={`/blog/${slug}`}>Learn more</Link>
          </Col>
          {featuredImage && (
            <Visible md lg xl>
              <Col md={5} {...imageColProps}>
                <StyledImg {...featuredImage} />
              </Col>
            </Visible>
          )}
        </Row>
      </Container>
    </Wrapper>
  );
}

BlogListItem.defaultProps = {
  className: null,
  category: null,
  title: null,
  content: null,
  slug: null,
  featuredImage: null,
  contentPosition: 'right',
};

BlogListItem.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  slug: PropTypes.string,
  contentPosition: PropTypes.oneOf(['right', 'left']),
  featuredImage: PropTypes.shape({}),
};

export default BlogListItem;
