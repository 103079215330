import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  max-width: 450px;
`;

const Item = styled.button`
  color: #0276f1;
  height: 60px;
  flex: 1;
  font-size: 16px;
  line-height: 28px;
  border: none;
  background: none;
  outline: none;
  transition: all 250ms ease;
  cursor: pointer;
`;

const ActiveItem = styled(Item)`
  background: #0276f1;
  border-radius: 3px;
  color: #ffffff;
  cursor: default;
`;

function CategorySwitcher({ className, items, activeKey, onChange }) {
  const handleChange = key => () => onChange(key);

  return (
    <Wrapper className={className}>
      {items.map(item =>
        item.key === activeKey ? (
          <ActiveItem onClick={handleChange(item.key)} key={item.key}>
            {item.label}
          </ActiveItem>
        ) : (
          <Item onClick={handleChange(item.key)} key={item.key}>
            {item.label}
          </Item>
        )
      )}
    </Wrapper>
  );
}

CategorySwitcher.defaultProps = {
  className: null,
};

CategorySwitcher.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  activeKey: PropTypes.string.isRequired,
};

export default CategorySwitcher;
